/* eslint-disable import/prefer-default-export */
// TODO remove when eslint rules implemented
import { useState, useEffect } from 'react';
import { app } from '../services/ms-teams-service';

export const useAppInitialization = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initializeApp = async () => {
      try {
        await app.initialize();
        setIsInitialized(true);
      } catch (e) {
        console.error('App initialization failed:', e);
        app.notifyFailure({
          reason: app.FailedReason.Other,
          message: 'App initialization failed',
        });
      } finally {
        setIsLoading(false);
      }
    };
    initializeApp();
  }, []);

  return { isInitialized, isLoading };
};
