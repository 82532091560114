import { authentication } from './ms-teams-service';
import {
  GET_USER_ACCESS,
  GET_SIGNED_IN_USER,
  GET_PEOPLE,
  GET_PRESENCE,
  GET_MEETINGS,
  GET_SIGNED_IN_USER_PHOTO,
  POST_ALLOW_SHARE_COLOUR_ENERGIES,
  POST_CONFIRM_COLOUR_ENERGIES,
  POST_UPDATE_USER_SETTINGS,
  GET_CHAT_INFO,
  GET_CHANNEL_MEMBERS,
  GET_LEARNER_INFO,
  POST_BOT_MESSAGE,
  POST_FEEDBACK_MESSAGE,
  GET_USER_SETTINGS,
  POST_INSTALL_MEETING_TAB,
  POST_ADD_NOMINATED_USER,
  POST_SUPPORT_REQUEST_MESSAGE,
  ATTACH_PEOPLE_AVATAR_AND_PRESENCE,
  UPDATE_USER_PHOTO,
  GET_USER,
  ADD_PROFILE_VIEW,
  POST_COMPARE_INFO,
  POST_UPDATE_USER_STATEMENT_SETTINGS,
  DELETE_USER,
  GET_LEARNER_RECORD,
  PUT_LEARNER_PROFILE_DATE,
  POST_RESET_STATEMENTS_REQUEST_MESSAGE,
  PUT_LEARNER_LNG_CODE,
} from '../config/api';
import api from '../config/http';
import { CompareProfileUser, EnergyDisplayData } from '../global/types';

/**
 * Requests an Azure AD token to be issued on behalf of the app. The token is acquired from the
 * cache if it is not expired. Otherwise a request is sent to Azure AD to obtain a new token.
 * @returns token
 */
const getAuthToken = async () => {
  const token = await authentication.getAuthToken();

  return token;
};

export const getUserAccess = async (tenantId: string | null) => {
  const token = await getAuthToken();
  const response = await api('get', `${GET_USER_ACCESS}/${tenantId}`, {
    Authorization: `Bearer ${token}`,
  });

  return response;
};

export const getSignedInUser = async (tenantId: string | null) => {
  const token = await getAuthToken();
  const response = await api('get', `${GET_SIGNED_IN_USER}/${tenantId}`, {
    Authorization: `Bearer ${token}`,
  });

  return response;
};

export const getSignedInUserPhoto = async () => {
  const token = await getAuthToken();
  const response = await api('get', GET_SIGNED_IN_USER_PHOTO, {
    Authorization: `Bearer ${token}`,
  });

  return response;
};

export const updateUserPhoto = async (image: string) => {
  const token = await getAuthToken();
  const response = await api(
    'put',
    UPDATE_USER_PHOTO,
    {
      Authorization: `Bearer ${token}`,
    },
    { image },
  );

  return response;
};

export const getUser = async (userEmail: string) => {
  const token = await getAuthToken();
  const response = await api('get', `${GET_USER}/${userEmail}`, {
    Authorization: `Bearer ${token}`,
  });
  return response;
};

export const postComparisonInfo = async (
  p1: CompareProfileUser,
  p2: CompareProfileUser,
) => {
  const token = await getAuthToken();
  const response = await api(
    'post',
    `${POST_COMPARE_INFO}`,
    {
      Authorization: `Bearer ${token}`,
    },
    {
      p1,
      p2,
    },
  );
  return response;
};

export const getMeetings = async () => {
  const token = await getAuthToken();
  const response = await api('get', GET_MEETINGS, {
    Authorization: `Bearer ${token}`,
  });
  return response;
};

export const installMeetingTab = async (chatId: string) => {
  const token = await getAuthToken();
  const response = await api(
    'post',
    POST_INSTALL_MEETING_TAB,
    {
      Authorization: `Bearer ${token}`,
    },
    {
      chatId,
    },
  );
  return response;
};

export const getChatInfo = async (chatId: string) => {
  const token = await getAuthToken();
  const response = await api('get', `${GET_CHAT_INFO}/${chatId}`, {
    Authorization: `Bearer ${token}`,
  });
  return response;
};

export const getChannelMembers = async (
  teamId: string | undefined,
  channelId: string | undefined,
) => {
  const token = await getAuthToken();
  const response = await api(
    'get',
    `${GET_CHANNEL_MEMBERS}/${teamId}/${channelId}`,
    {
      Authorization: `Bearer ${token}`,
    },
  );
  return response;
};

interface ShareEnergiesParam {
  allowShareColourEnergies: number;
}

export const postAllowShareColourEnergies = async (
  userEmail: string,
  data: ShareEnergiesParam,
) => {
  const token = await getAuthToken();
  const response = await api(
    'post',
    `${POST_ALLOW_SHARE_COLOUR_ENERGIES}/${userEmail}`,
    {
      Authorization: `Bearer ${token}`,
    },
    data,
  );
  return response;
};

export const postBotInsightsMessage = async (
  userEmail: string,
  tenantId: string,
) => {
  const token = await getAuthToken();
  const response = await api(
    'post',
    `${POST_BOT_MESSAGE}`,
    {
      Authorization: `Bearer ${token}`,
    },
    {
      id: userEmail, // UPN
      tenantId,
    },
  );
  return response;
};

export const postFeedbackBotMessage = async (
  userEmail: string,
  tenantId: string,
) => {
  const token = await getAuthToken();
  const response = await api(
    'post',
    `${POST_FEEDBACK_MESSAGE}`,
    {
      Authorization: `Bearer ${token}`,
    },
    {
      id: userEmail, // UPN
      tenantId,
    },
  );
  return response;
};

export const postResetStatementBotMessage = async (
  userEmail: string,
  tenantId: string,
) => {
  const token = await getAuthToken();
  const response = await api(
    'post',
    `${POST_RESET_STATEMENTS_REQUEST_MESSAGE}`,
    {
      Authorization: `Bearer ${token}`,
    },
    {
      id: userEmail, // UPN
      tenantId,
    },
  );
  return response;
};

export const getLearnerRecord = async (userEmail: string) => {
  const token = await getAuthToken();
  const response = await api('get', `${GET_LEARNER_RECORD}/${userEmail}`, {
    Authorization: `Bearer ${token}`,
  });
  return response;
};

export const putLearnerProfileDate = async (
  createdAtProfileDateAPI: string,
  userEmail: string,
) => {
  const token = await getAuthToken();
  const response = await api(
    'put',
    `${PUT_LEARNER_PROFILE_DATE}/${userEmail}`,
    {
      Authorization: `Bearer ${token}`,
    },
    {
      createdAtProfileDateAPI,
    },
  );
  return response;
};

export const putLearnerLngCode = async (
  userEmail: string,
  lngCode: string,
) => {
  const token = await getAuthToken();
  const response = await api(
    'put',
    `${PUT_LEARNER_LNG_CODE}/${userEmail}`,
    {
      Authorization: `Bearer ${token}`,
    },
    {
      lngCode,
    },
  );
  return response;
};

export const postConfirmColourEnergies = async (
  userEmail: string,
  tenantId: string | null,
) => {
  const token = await getAuthToken();
  const response = await api(
    'post',
    `${POST_CONFIRM_COLOUR_ENERGIES}/${userEmail}`,
    {
      Authorization: `Bearer ${token}`,
    },
    { tenantId },
  );
  return response;
};

export const postUpdateUserSettings = async (
  userEmail: string,
  settings: string,
) => {
  const token = await getAuthToken();
  const response = await api(
    'post',
    `${POST_UPDATE_USER_SETTINGS}/${userEmail}`,
    {
      Authorization: `Bearer ${token}`,
    },
    { settings },
  );
  return response;
};

export const postUpdateUserStatementSettings = async (
  userEmail: string,
  settings: string,
) => {
  const token = await getAuthToken();
  const response = await api(
    'post',
    `${POST_UPDATE_USER_STATEMENT_SETTINGS}/${userEmail}`,
    {
      Authorization: `Bearer ${token}`,
    },
    { settings },
  );
  return response;
};

export const postAddNominatedPerson = async (
  email: string,
  tenantId: string,
) => {
  const token = await getAuthToken();
  const response = await api(
    'post',
    `${POST_ADD_NOMINATED_USER}`,
    {
      Authorization: `Bearer ${token}`,
    },
    { email, tenantId },
  );
  return response;
};

export const postSupportRequestBotMessage = async (
  userName: string,
  userEmail: string,
  tenantId: string | null,
  supportRequestType: string,
) => {
  const token = await getAuthToken();
  const response = await api(
    'post',
    `${POST_SUPPORT_REQUEST_MESSAGE}`,
    {
      Authorization: `Bearer ${token}`,
    },
    {
      userName,
      id: userEmail, // UPN
      tenantId,
      supportRequestType,
    },
  );
  return response;
};

export const attachPeopleAvatarAndPresence = async (
  people: EnergyDisplayData,
) => {
  const token = await getAuthToken();
  const response = await api(
    'post',
    `${ATTACH_PEOPLE_AVATAR_AND_PRESENCE}/`,
    {
      Authorization: `Bearer ${token}`,
    },
    people,
  );
  return response;
};

export const addProfileView = async (
  userEmail: string,
  compareToEmail: string,
) => {
  const token = await getAuthToken();
  const response = await api(
    'post',
    `${ADD_PROFILE_VIEW}/${userEmail}`,
    {
      Authorization: `Bearer ${token}`,
    },
    {
      compareToEmail,
    },
  );
  return response;
};

export const deleteUserRequest = async (userEmail: string) => {
  const token = await getAuthToken();
  const response = await api('delete', `${DELETE_USER}/${userEmail}`, {
    Authorization: `Bearer ${token}`,
  });
  return response;
};
