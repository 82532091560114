/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/function-component-definition */
// TODO remove when eslint rules implemented
import { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Spinner from './components/common/Spinners/Spinner';

const Dashboard = lazy(() => import('./pages/Dashboard'));
const Settings = lazy(() => import('./pages/Settings'));
const InsightsFlow = lazy(() => import('./pages/InsightsFlow'));
const ChatTab = lazy(() => import('./pages/ChatTab'));
const EnergyTabConfig = lazy(() => import('./pages/EnergyTabConfig'));
const CompareProfiles = lazy(() => import('./pages/CompareProfiles'));
const PersonalProfileModal = lazy(() => import('./pages/PersonalInfoModal'));
const Avatar = lazy(() => import('./pages/Avatar'));
const ClosePopUp = lazy(() => import('./pages/ClosePopup'));
const OpenPopup = lazy(() => import('./pages/OpenPopup'));
const Error = lazy(() => import('./pages/Error'));

const AppRoutes = ({ isInitialized }: { isInitialized: boolean }) => (
  <Suspense fallback={<Spinner theme="default" animationName="spin" />}>
    <Routes>
      {!isInitialized ? (
        <Route path="*" element={<Navigate to="/error" replace />} />
      ) : (
        <>
          <Route path="/" element={<Dashboard />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/insights" element={<InsightsFlow />} />
          <Route path="/chat" element={<ChatTab />} />
          <Route path="/tab" element={<EnergyTabConfig />} />
          <Route path="/compare-profiles" element={<CompareProfiles />} />
          <Route path="/customise-statements" element={<PersonalProfileModal />} />
          <Route path="/avatar" element={<Avatar />} />
          <Route path="/auth-end" element={<ClosePopUp />} />
          <Route path="/auth-start" element={<OpenPopup />} />
          {/* Fallback for undefined routes */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </>
      )}
      <Route path="/error" element={<Error />} />
    </Routes>
  </Suspense>
);

export default AppRoutes;
